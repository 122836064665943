/*!

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// React imports
import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';

// node.js library that concatenates classes (strings)
import classnames from "classnames";

// javascipt plugin for creating charts
import { Chart } from "chart.js";

// react plugin used to create charts
import { Line, Bar } from "react-chartjs-2";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardImg,
  CardTitle,
  FormGroup,
  Form,
  Input,
  ListGroupItem,
  ListGroup,
  NavItem,
  NavLink,
  Nav,
  Progress,
  Container,
  Row,
  Col,
  Table,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from "reactstrap";

// Authentication
import { auth, firestore } from "./../../firebase";
import { useAuthState } from "react-firebase-hooks/auth";

// core components
import ProfileHeader from "components/Headers/ProfileHeader.js";

import {
  SET_PERSONAS,
  SET_ACTIVE_ENTITY,
  SET_PERSONA,
  SET_QUERY
} from 'store/actions';

import {
  chartOptions,
  parseOptions,
  chartExample1,
  chartExample2,
} from "variables/charts.js";

// Persona (master)
const masterPersona = `
You are one of several possible buying persona's for a lobster delivery service retailer named Get Main Lobster that delivers fresh Maine lobster and seafood directly to customers doorsteps.

Here are the possible buying persona's that you might be and details about your purchase criteria.

Persona: Seafood Connoisseur
About: An Epicure Who Prioritizes Quality, Freshness, and Authentic Maine Lobsters Delivered Straight to the Doorstep
Primary Purchase Criteria: (1) Premium Freshness, (2) Rapid Delivery, (3) Authentic Maine Lobster

Persona: Nostalgic Consumer
About: For those who crave a taste of nostalgia with Fresh, Authentic Maine Lobsters Delivered to their Doorsteps
Primary Purchase Criteria: (1) Authenticity, (2) Speed of Delivery, (3) Quality of Packaging
`;

function Home({ profile, account, knowledge, insights, report, model, dispatch }) {

  // Get history
  const history = useHistory();

  // Load persona data
  React.useEffect(() => {
    // Query firestore
    firestore.collection("personas")
      .where("account", "==", "wicked-reports")
      .where("client", "==", "getmainelobster")
      .onSnapshot((querySnapshot) => {
        // Iterate and append to list
        let personas = [];
        querySnapshot.forEach((doc) => { personas.push(doc.data().data); });

        // Update personas
        dispatch({
          type: SET_PERSONAS,
          payload: personas
        });
        console.log("Personas: ", personas);
      }, (error) => {
        console.log(error);
      });
  }, [ ]);

  // Query iunput
  const [query, setQuery] = React.useState("");

  // Handle text query change
  const handleChange = (e) => {
    // Set query
    setQuery(e.target.value);
  }

  // Handle artwork selection
  const selectEntity = (entity) => {
    // Update local store
    dispatch({
      type: SET_ACTIVE_ENTITY,
      payload: entity
    });

    // Got to review page
    history.push(`/admin/customers/personas/view/${entity.id}`);
  }

  return (
    <>
      <ProfileHeader />
      <Container className="mt--6" fluid>
        <Row>
          <Col xl="4">
            <Link to="/admin/customers/personas">
              <Card
                className="bg-gradient-primary border-0"
                style={{ cursor: 'pointer', height: 140 }}
              >
                <CardBody>
                  <Row>
                    <div className="col">
                      <CardTitle
                        className="text-uppercase text-muted mb-0 text-white"
                        tag="h5"
                      >
                        Customer
                      </CardTitle>
                      <span className="h2 font-weight-bold mb-0 text-white">
                        Personas
                      </span>
                    </div>
                    <Col className="col-auto">
                      <div className="icon icon-shape bg-white text-dark rounded-circle shadow">
                        <i className="ni ni-single-02" />
                      </div>
                    </Col>
                  </Row>
                  <p className="mt-3 mb-0 text-sm">
                    <span className="text-info mr-2">
                      <i className="fa fa-arrow-up" /> 58.84%
                    </span>{" "}
                    <span className="text-white text-nowrap">Model Strength</span>
                  </p>
                </CardBody>
              </Card>
            </Link>
          </Col>

          <Col xl="8">
            <Card
              className="bg-gradient-primary border-0"
              style={{
                height: 140
              }}
            >
              <CardBody>
                <Row>
                  <Col md="12">
                    <Input
                      className="form-control"
                      type="text"
                      placeholder="Ask a question about your customers..."
                      style={{
                        zIndex: 100000000,
                        position: 'absolute',
                        width: '90%',
                        top: 5
                      }}
                      onChange={handleChange}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                          // Set persona
                          dispatch({
                            type: SET_PERSONA,
                            payload: {
                              persona: masterPersona
                            }
                          });

                          // Set initial query
                          if(query.length > 0) {
                            dispatch({
                              type: SET_QUERY,
                              payload: {
                                query: query
                              }
                            });
                          }

                          // Go to agent
                          history.push(`/admin/knowledge/agent`);
                        }
                      }}
                    />
                    <div
                      className="icon icon-shape bg-white text-dark rounded-circle shadow"
                      style={{
                        zIndex: 200000000,
                        position: 'absolute',
                        right: '2%',
                        top: -2,
                        height: 80,
                        width: 80
                      }}
                    >
                      <i className="ni ni-books" />
                    </div>
                  </Col>
                  <Col
                    md="12"
                    style={{
                      paddingTop: 65
                    }}
                  >
                    <Button onClick={() => {
                      // Select persona
                      const topic = {
                        name: "Freshness Guarantee",
                        description: "their commitment to providing premium, fresh Maine lobsters directly to your doorstep, ensuring the highest quality and taste"
                      };

                      // Set persona
                      dispatch({
                        type: SET_PERSONA,
                        payload: {
                          persona: masterPersona
                        }
                      });

                      dispatch({
                        type: SET_QUERY,
                        payload: {
                          query: `Can you tell me more about how you evaluate retailers like us on their ${topic.name}. Specifically, ${topic.description}.`
                        }
                      });

                      // Go to agent
                      history.push(`/admin/knowledge/agent`);
                    }} color="default" size="sm" type="button">
                      freshness guarantee
                    </Button>
                    <Button onClick={() => {
                      // Select persona
                      const topic = {
                        name: "Source and Sustainability",
                        description: "their dedication to sourcing authentic Maine lobsters sustainably and supporting local fishermen and communities in the process"
                      };

                      // Set persona
                      dispatch({
                        type: SET_PERSONA,
                        payload: {
                          persona: masterPersona
                        }
                      });

                      dispatch({
                        type: SET_QUERY,
                        payload: {
                          query: `Can you tell me more about how you evaluate retailers like us on their ${topic.name}. Specifically, ${topic.description}.`
                        }
                      });

                      // Go to agent
                      history.push(`/admin/knowledge/agent`);
                    }} color="default" size="sm" type="button">
                      source and sustainability
                    </Button>
                    <Button onClick={() => {
                      // Select persona
                      const topic = {
                        name: "Packaging and Presentation",
                        description: "their ability to ensure that our lobsters are delivered in high-quality packaging, maintaining their freshness and presentation throughout the delivery process."
                      };

                      // Set persona
                      dispatch({
                        type: SET_PERSONA,
                        payload: {
                          persona: masterPersona
                        }
                      });

                      dispatch({
                        type: SET_QUERY,
                        payload: {
                          query: `Can you tell me more about how you evaluate retailers like us on their ${topic.name}. Specifically, ${topic.description}.`
                        }
                      });

                      // Go to agent
                      history.push(`/admin/knowledge/agent`);
                    }} color="default" size="sm" type="button">
                      packaging and presentation
                    </Button>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col xl="12">
            <Card>
              <CardBody>
                <Row>
                  <Col md="12">
                    <Card>
                      <CardHeader className="border-0">
                        <Row>
                          <Col xs="8">
                            <h3 className="mb-0">Persona Performance</h3>
                          </Col>
                          <Col className="text-right" xs="4">
                          </Col>
                        </Row>
                      </CardHeader>

                      <Table className="align-items-center table-flush" responsive>
                        <thead className="thead-light">
                          <tr>
                            <th className="sort" data-sort="name" scope="col">
                              Name
                            </th>
                            <th className="sort" data-sort="matched" scope="col">
                              Matched
                            </th>
                            <th className="sort" data-sort="segments" scope="col">
                              Segments
                            </th>
                            <th className="sort" data-sort="value" scope="col">
                              Lifetime Value (Avg.)
                            </th>
                            <th className="sort" data-sort="model" scope="col">
                              Model Strength
                            </th>
                            <th scope="col" />
                          </tr>
                        </thead>
                        <tbody className="list">
                          { knowledge.entities.map((entity, index) => (
                            <tr key={index}>
                              <th scope="row">
                                {entity.name}
                              </th>
                              <td className="matched">{entity.total}</td>
                              <td className="segments">{entity.segments ? entity.segments.length : 0}</td>
                              <td className="value">{entity.value}</td>
                              <td className="model">
                                <div className="d-flex align-items-center">
                                  <span className="completion mr-2">{entity.strength}%</span>
                                  <div>
                                    <Progress max="100" value={entity.strength} color="primary" />
                                  </div>
                                </div>
                              </td>
                              <td className="text-right">
                                <UncontrolledDropdown group>
                                    <Button
                                      color="primary" size="sm" type="button"
                                      onClick={() => {
                                        // select entity
                                        selectEntity(entity);
                                      }}
                                      style={{
                                        width: 120
                                      }}
                                    >
                                      View
                                    </Button>
                                    <DropdownToggle
                                      caret
                                      color="primary"
                                    />
                                    <DropdownMenu>
                                      <DropdownItem disabled>
                                        Cancel
                                      </DropdownItem>
                                    </DropdownMenu>
                                  </UncontrolledDropdown>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </Card>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

// Connect to store
const ConnectedHome = connect(state => ({
  profile: state.profile,
  account: state.account,
  knowledge: state.knowledge,
  insights: state.insights,
  report: state.report,
  model: state.model
}))(Home);

export default ConnectedHome;
