// action - state management
import * as actionTypes from '../actions';

const initialState = {
    initialized: false,
    domain: {
      name: "Customers",
      id: "@axon/commerce/customers",
      display: "2,345 Total Customers"
    },
    entity: {
      name: "Persona 1",
      total: 100,
      value: "$2,500",
      strength: 80,
      factors: [],
      customers: [],
      dimensions: [],
      profile: {
          card: {
              gender: "Female",
              age: "25-55",
              location: "Urban, Metropolitan Areas",
              job: "Health Professionals, Fitness Coaches, Top-level Executives"
          },
          summary: {
              name: "Health-Savvy Seafood Lovers",
              headline: "Experience Freshness with Every Bite from Get Maine Lobster Delivered to Your Doorstep",
              details: "This purchaser is a health-conscious consumer who prioritizes fresh, natural, and nourishing food sources. They enjoy looking for the nutritional benefits of seafood and are likely to buy from Get Maine Lobster for the fresh and high-quality seafood selection it offers. This consumer cares deeply about sustainable fishing practices, appreciates the rapid delivery service, and values eco-friendly packaging for preserving freshness and reducing environmental impact."
          },
          kpis: [
              {
                  "name": "Average Lifetime Value",
                  "value": "$0.00"
              },
              {
                  "name": "Average Total Orders",
                  "value": "0.0"
              },
              {
                  "name": "Average Order Value",
                  "value": "$0.00"
              }
          ]
      },
      demographics: {
          age: 48.1,
          gender: {
              male: 54,
              female: 46
          },
          income: "$0.00"
      }
    },
    factors: [
      {
        id: "@axon/commerce/customers/age",
        name: "Fit and Comfort",
        value: 90,
        display: "Fit and Comfort",
        summary: "Fit Quality",
        details: "As a business casual buyer, a well-fitted, comfortable shirt is essential. Teddy Stratford's athletic fit shirts are designed for a closer fit, making them suitable for both formal and casual occasions."
      },
      {
        id: "@axon/commerce/customers/age",
        name: "Material Quality",
        value: 85,
        display: "Material Quality",
        summary: "Fabric Durability",
        details: "High-quality materials translate into durability and longevity. Being a professional, I appreciate shirts that remain pristine even after multiple wears and washes."
      },
      {
        id: "@axon/commerce/customers/age",
        name: "Versatility",
        value: 80,
        display: "Versatility",
        summary: "Styling Ease",
        details: "I prefer shirts that can transition seamlessly from the office to a night out. I value clothing that could be paired with other items in my existing wardrobe and can be worn in various scenarios."
      },
      {
        id: "@axon/commerce/customers/age",
        name: "Convenience",
        value: 70,
        display: "Convenience",
        summary: "Easy Maintenance",
        details: "As a busy professional, I favour shirts that require minimal maintenance. This means shirts that are easy to care for, perhaps requiring no to little ironing or special laundry care."
      },
      {
        id: "@axon/commerce/customers/age",
        name: "Brand Ethos",
        value: 60,
        display: "Brand Ethos",
        summary: "Business Values",
        details: "The brand's ethos and their stance on various issues such as sustainability, ethical manufacturing and fair trade practices would also influence my purchasing decision. I want to align my purchases with my personal values."
      }
    ],
    stats: [
      {
        id: "@axon/commerce/customers/age",
        name: "$100 - $200",
        value: 15,
        display: "LTV: $100 - $200"
      },
      {
        id: "@axon/commerce/customers/age",
        name: "$200 - $300",
        value: 30,
        display: "LTV: $200 - $300"
      },
      {
        id: "@axon/commerce/customers/age",
        name: "$300 - $500",
        value: 45,
        display: "LTV: $300 - $500"
      },
      {
        id: "@axon/commerce/customers/age",
        name: "$500 - $800",
        value: 10,
        display: "LTV: $500 - $800"
      },
      {
        id: "@axon/commerce/customers/age",
        name: "$800+",
        value: 5,
        display: "LTV: $800+"
      }
    ],
    items: [
      {
        name: "Clara Thompson",
        gender: "Female",
        age: "28",
        value: "$1,432.23",
        days_to_purchase: "2 Days",
        education: "UC Santa Barbara (American History), Chapman University (Real Estate)",
        industry: "Tech Industry",
        jobTitle: "UX Designer",
        jobFunction: "Lawyer",
        interests: "cooking, exercise, electronics, outdoors, investing, politics, home improvement, legal research, reading, crafts, gourmet cooking, sports, music, travel, movies, home decoration, film"
      }
    ]
};

// ===========================|| PRODUCTS REDUCER ||=========================== //

const modelReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.INIITIALIZE_MODEL: {
          console.log("Initializing model...", action.payload);

          // Update state
          return {
              ...state,
              initialized: true
          };
        }
        case actionTypes.SET_ACTIVE_ENTITY: {
          console.log("Setting active entity...", action.payload);

          // Update state
          return {
              ...state,
              entity: action.payload
          };
        }
        case actionTypes.SET_DIMENSIONS: {
          console.log("Setting persona dimensions...", action.payload);

          // Update state
          return {
              ...state,
              entity: {
                ...state.entity,
                dimensions: action.payload
              }
          };
        }
        default:
            return state;
    }
};

export default modelReducer;



/*

stats: [
  {
    id: "@axon/commerce/customers/age",
    name: "$100 - $200",
    value: 15,
    display: "LTV: $100 - $200"
  },
  {
    id: "@axon/commerce/customers/age",
    name: "$200 - $300",
    value: 30,
    display: "LTV: $200 - $300"
  },
  {
    id: "@axon/commerce/customers/age",
    name: "$300 - $500",
    value: 45,
    display: "LTV: $300 - $500"
  },
  {
    id: "@axon/commerce/customers/age",
    name: "$500 - $800",
    value: 10,
    display: "LTV: $500 - $800"
  },
  {
    id: "@axon/commerce/customers/age",
    name: "$800+",
    value: 5,
    display: "LTV: $800+"
  }
],

*/
