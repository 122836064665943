/*!

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useState, useEffect } from "react";
import { Link, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import _ from 'lodash';

// node.js library that concatenates classes (strings)
import classnames from "classnames";

// react plugin used to create datetimepicker
import ReactDatetime from "react-datetime";

// react plugin used to create DropdownMenu for selecting items
import Select2 from "react-select2-wrapper";

// react plugin used to create charts
import { Line, Bar } from "react-chartjs-2";

// Sparklines
import { Sparklines, SparklinesLine, SparklinesBars, SparklinesSpots, SparklinesNormalBand } from 'react-sparklines';

// reactstrap components
import {
  Badge,
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  UncontrolledDropdown,
  Form,
  FormGroup,
  Label,
  Input,
  ListGroupItem,
  ListGroup,
  Media,
  NavItem,
  NavLink,
  Nav,
  Pagination,
  PaginationItem,
  PaginationLink,
  Progress,
  Table,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
  Spinner
} from "reactstrap";

// Formatting
import accounting from 'accounting-js';

// core components
import KnowledgeHeader from "components/Headers/KnowledgeHeader.js";

// Auth actions
import {
  INIITIALIZE_INSIGHTS,
  SET_ACTIVE_ENTITY
} from 'store/actions';

// Import report blocks
import CustomerChart from "./blocks/chart.js";

function Personas({ profile, account, knowledge, insights, report, dispatch }) {
  console.log("Account:", account);
  console.log("Knowledge:", knowledge);
  console.log("Insights: ", insights);
  console.log("Report:", report);

  // Get history
  const history = useHistory();

  // States (Report)
  const [startDate, setStartDate] = React.useState(report.filters.timeframe.startDate);
  const [endDate, setEndDate] = React.useState(report.filters.timeframe.endDate);
  const [activeNav, setActiveNav] = React.useState(1);
  const listRef = React.useRef(null);

  // Sync insights
  useEffect(() => {
    if (account.initialized && !insights.initialized) {
      // Initialize insights
      dispatch({
        type: INIITIALIZE_INSIGHTS,
        payload: {
          filters: {
            timeframe: {
              startDate: "",
              endDate: ""
            },
            clients: {
              list: account.clients,
              selected: (account.clients.length > 0) ? account.clients[0] : null
            }
          }
        }
      });
    }
  }, [ account ]);

  // Handle artwork selection
  const selectEntity = (entity) => {
    // Update local store
    dispatch({
      type: SET_ACTIVE_ENTITY,
      payload: entity
    });

    // Got to review page
    history.push(`/admin/customers/personas/view/${entity.id}`);
  }

  return (
    <>
      <KnowledgeHeader
        headline="Personas"
        description="AI personas provide valuable, on-going insights into the needs and behaviors of a specific target audience."
      />
      <Container className="mt--6" fluid>
        <Row>
          <Col xl="12">
            <Card>
              <CardHeader className="border-0">
                <h3 className="mb-0">Active Personas</h3>
              </CardHeader>

              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th className="sort" data-sort="name" scope="col">
                      Name
                    </th>
                    <th className="sort" data-sort="matched" scope="col">
                      Matched
                    </th>
                    <th className="sort" data-sort="segments" scope="col">
                      Segments
                    </th>
                    <th className="sort" data-sort="value" scope="col">
                      Lifetime Value (Avg.)
                    </th>
                    <th className="sort" data-sort="model" scope="col">
                      Model Strength
                    </th>
                    <th scope="col" />
                  </tr>
                </thead>
                <tbody className="list">
                  { knowledge.entities.map((entity, index) => (
                    <tr key={index}>
                      <th scope="row">
                        {entity.name}
                      </th>
                      <td className="matched">{entity.total}</td>
                      <td className="segments">{entity.segments ? entity.segments.length : 0}</td>
                      <td className="value">{entity.value}</td>
                      <td className="model">
                        <div className="d-flex align-items-center">
                          <span className="completion mr-2">{entity.strength}%</span>
                          <div>
                            <Progress max="100" value={entity.strength} color="primary" />
                          </div>
                        </div>
                      </td>
                      <td className="text-right">
                        <UncontrolledDropdown group>
                            <Button
                              color="primary" size="sm" type="button"
                              onClick={() => {
                                // select entity
                                selectEntity(entity);
                              }}
                              style={{
                                width: 120
                              }}
                            >
                              View
                            </Button>
                            <DropdownToggle
                              caret
                              color="primary"
                            />
                            <DropdownMenu>
                              <DropdownItem disabled>
                                Cancel
                              </DropdownItem>
                            </DropdownMenu>
                          </UncontrolledDropdown>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>

              <CardFooter className="py-4">

              </CardFooter>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

// Connect to store
const ConnectedPersonas = connect(state => ({
  profile: state.profile,
  account: state.account,
  knowledge: state.knowledge,
  insights: state.insights,
  report: state.report
}))(Personas);

export default ConnectedPersonas;
