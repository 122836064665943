// action - state management
import * as actionTypes from '../actions';

const initialState = {
    initialized: false,

    // Core
    selected: null,
    outline: [],
    stats: [
      {
        id: "@axon/commerce/orders/daysToPurchase",
        name: "0",
        value: 20,
        display: "Days: 0"
      },
      {
        id: "@axon/commerce/orders/daysToPurchase",
        name: "1",
        value: 60,
        display: "Days: 1"
      },
      {
        id: "@axon/commerce/orders/daysToPurchase",
        name: "2",
        value: 5,
        display: "Days: 2"
      },
      {
        id: "@axon/commerce/orders/daysToPurchase",
        name: "3 - 5",
        value: 3,
        display: "Days: 3 - 5"
      },
      {
        id: "@axon/commerce/orders/daysToPurchase",
        name: "6 - 10",
        value: 4,
        display: "Days: 6 - 10"
      },
      {
        id: "@axon/commerce/orders/daysToPurchase",
        name: "11 - 14",
        value: 2,
        display: "Days: 11 - 14"
      },
      {
        id: "@axon/commerce/orders/daysToPurchase",
        name: "15+",
        value: 7,
        display: "Days: 15+"
      }
    ],
    kpis: [
      [
        {
          name: "Total Sales",
          value: "$51,787.98"
        },
        {
          name: "Total Orders",
          value: "219"
        },
        {
          name: "Time to Purchase",
          value: "5.2 Days"
        }
      ],
      [
        {
          name: "Average Lifetime Value",
          value: "$366.93"
        },
        {
          name: "Average Total Orders",
          value: "2.1"
        },
        {
          name: "Average Order Value",
          value: "$177.56"
        }
      ]
    ],
    readouts: [
      // {
      //   name: "Performance Summary",
      //   type: "kpi",
      //   data: [
      //     {
      //       name: "Total Sales",
      //       value: "$51,787.98"
      //     },
      //     {
      //       name: "Total Orders",
      //       value: "219"
      //     },
      //     {
      //       name: "Time to Purchase",
      //       value: "5.2 Days"
      //     }
      //   ]
      // },
      {
        name: "Campaign Performance",
        type: "table",
        data: {
          headers: [
            {
              display: "Campaign Name",
              name: "campaign_name"
            },
            {
              display: "Total Orders",
              name: "total_orders"
            },
            {
              display: "Total Sales",
              name: "total_sales"
            },
            {
              display: "Days to Purchase",
              name: "days_to_purchase"
            }
          ],
          rows: [
            {
                campaign_name: "[CF] Prospecting - Conversion",
                total_orders: 361,
                total_sales: 79795.44,
                days_to_purchase: 2.13
            }
          ]
        }
      }
    ],
    filters: {
      timeframe: {
        startDate: "2022-12-01",
        endDate: "2023-01-31"
      }
    }
};

// ===========================|| PRODUCTS REDUCER ||=========================== //

const reportReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.INIITIALIZE_REPORT: {
          console.log("Initializing report...", action.payload);

          // Update state
          return {
              ...state,
              initialized: true
          };
        }
        case actionTypes.SET_ACTIVE_REPORT: {
          console.log("Setting active report...", action.payload);

          // Update state
          return {
              ...state,
              selected: action.payload
          };
        }
        case actionTypes.SET_FILTER_TIMEFRAME: {
          console.log("Setting filter timeframe...", action.payload);

          // Update state
          return {
              ...state,
              filters: {
                timeframe: action.payload
              }
          };
        }
        default:
            return state;
    }
};

export default reportReducer;


/*


stats: [
  {
    id: "@axon/commerce/customers/age",
    name: "18 - 25",
    value: 15,
    display: "Age: 18 - 25"
  },
  {
    id: "@axon/commerce/customers/age",
    name: "25 - 30",
    value: 30,
    display: "Age: 25 - 30"
  },
  {
    id: "@axon/commerce/customers/age",
    name: "30 - 45",
    value: 45,
    display: "Age: 30 - 45"
  },
  {
    id: "@axon/commerce/customers/age",
    name: "45 - 55",
    value: 10,
    display: "Age: 45 - 55"
  },
  {
    id: "@axon/commerce/customers/age",
    name: "55+",
    value: 5,
    display: "Age: 55+"
  }
],


{
  name: "Performance Summary",
  type: "kpi",
  data: [
    {
      name: "Lifetime Value",
      value: "$1,269.25"
    },
    {
      name: "Total Orders",
      value: "5"
    },
    {
      name: "Time to Purchase",
      value: "5.2 Days"
    }
  ]
},



*/
