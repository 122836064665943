/*!

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useState, useEffect } from "react";
import { Link, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import _ from 'lodash';

// node.js library that concatenates classes (strings)
import classnames from "classnames";

// Import Algolio
import algoliasearch from 'algoliasearch';

// react plugin used to create datetimepicker
import ReactDatetime from "react-datetime";

// react plugin used to create DropdownMenu for selecting items
import Select2 from "react-select2-wrapper";

// react plugin used to create charts
import { Line, Bar } from "react-chartjs-2";

// Sparklines
import { Sparklines, SparklinesLine, SparklinesBars, SparklinesSpots, SparklinesNormalBand } from 'react-sparklines';

// reactstrap components
import {
  Badge,
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  UncontrolledDropdown,
  Form,
  FormGroup,
  Label,
  Input,
  ListGroupItem,
  ListGroup,
  Media,
  NavItem,
  NavLink,
  Nav,
  Pagination,
  PaginationItem,
  PaginationLink,
  Progress,
  Table,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
  Spinner
} from "reactstrap";

// Formatting
import accounting from 'accounting-js';

// Firestore
import { firestore } from "./../../../firebase";

// core components
import KnowledgeHeader from "components/Headers/KnowledgeHeader.js";

// Auth actions
import {
  INIITIALIZE_INSIGHTS,
  SET_CUSTOMERS,
  SET_ACTIVE_CUSTOMER
} from 'store/actions';

// Import report blocks
import CustomerChart from "./blocks/chart.js";

// Initialize search index
const client = algoliasearch("CH6J4O4SMF", "67af89cb73a546acb368d9bc44eb5f86");
const index = client.initIndex("Customers (Dev)");

function Customers({ profile, account, knowledge, insights, report, dispatch }) {
  console.log("Insights: ", insights);

  // Get history
  const history = useHistory();

  // States (Report)
  const [startDate, setStartDate] = React.useState(report.filters.timeframe.startDate);
  const [endDate, setEndDate] = React.useState(report.filters.timeframe.endDate);
  const [activeNav, setActiveNav] = React.useState(1);
  const listRef = React.useRef(null);

  // Grab deep link query params (quick and dirty)
  const queryParams = new URLSearchParams(window.location.search);
  const persona = queryParams.get("persona");
  const filter = queryParams.get("filter");

  // Filter customer list
  useEffect(() => {
    // Check if person adn filter
    if(persona && filter) {
      console.log(" -- Filtering customer list");

      // Load customer meta-data
      index.search('', {
        filters: `data.persona.name:'${persona}' AND data.location.city:'${filter}'`,
        page: 0,
        hitsPerPage: 10,
      }).then(({ hits, facets }) => {
        console.log("Search: ", hits, facets);

        // Set customers
        dispatch({
          type: SET_CUSTOMERS,
          payload: hits
        });
      }).catch((error) => {
        console.error(error);
      });
    }
  }, []);

  // Sync insights
  useEffect(() => {
    if (account.initialized && !insights.initialized) {
      // Initialize insights
      dispatch({
        type: INIITIALIZE_INSIGHTS,
        payload: {
          filters: {
            timeframe: {
              startDate: "",
              endDate: ""
            },
            clients: {
              list: account.clients,
              selected: (account.clients.length > 0) ? account.clients[0] : null
            }
          }
        }
      });
    }
  }, [ account ]);

  // Update customers when filters change
  useEffect(() => {
    // load data
    if(!persona) {
      // Query firestore
      firestore.collection("customers")
        .where("source.account", "==", "wicked-reports")
        .where("source.client", "==", "getmainelobster")
        .where("data.activity.enabled", "==", true)
        .orderBy("updated", "desc").limit(30)
        .onSnapshot((querySnapshot) => {
          // Iterate and append to list
          let customers = [];
          let customerData;
          querySnapshot.forEach((doc) => {
            // Get data
            customerData = doc.data();

            // Add to queue
            customers.push(customerData);
          });

          // Update personas
          console.log(" -> Customers: ", customers.slice(0, 10));
          dispatch({
            type: SET_CUSTOMERS,
            payload: customers.slice(0, 10)
          });
        }, (error) => {
          console.log(error);
        });
    }
  }, [ insights.filters ]);

  // Handle customer selection
  // const selectCustomer = (customer) => {
  //   // Got to review page
  //   history.push(`/admin/customers/insights/view/${customer.id}`);
  // }

  const selectCustomer = async (customer) => {
    // Load customer data
    firestore.collection("customers").doc(customer.id).get()
      .then((doc) => {
        if(doc.exists) {
          // Update customer
          const customerData = doc.data();

          // Update local store
          dispatch({
            type: SET_ACTIVE_CUSTOMER,
            payload: customerData
          });

          // Got to review page
          console.log(customerData);
          history.push(`/admin/customers/insights/view/${customer.id}`);
        }
      })
  }

  return (
    <>
      <KnowledgeHeader
        headline="Customers"
        description="View and analzye Persona-enriched customer data and behavioral segments."
      />
      <Container className="mt--6" fluid>
        <Row>
          <Col xl="12">
            <Card>
              <CardHeader className="border-0">
                <h3 className="mb-0">Active Customers</h3>
              </CardHeader>

              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th className="sort" data-sort="name" scope="col">
                      Name
                    </th>
                    <th className="sort" data-sort="persona" scope="col">
                      Persona
                    </th>
                    <th className="sort" data-sort="total_orders" scope="col">
                      Total Orders
                    </th>
                    <th className="sort" data-sort="lifetime_value" scope="col">
                      Lifetime Value
                    </th>
                    <th scope="col" />
                  </tr>
                </thead>
                <tbody className="list">
                  { insights.customers.map((customer, index) => (
                    <tr key={index}>
                      <th scope="row">
                        <div className="col ml--2">
                          <h4 className="mb-0">
                            <a href="#pablo" onClick={(e) => e.preventDefault()}>
                              {customer.data.first_name ? customer.data.first_name : ''} {customer.data.last_name ? customer.data.last_name : ''}
                            </a>
                          </h4>
                          <small>{ customer.data.personal.age ? `${customer.data.personal.age} | ` : '' }{ customer.data.professional.industry ? `${customer.data.professional.industry} |` : ''}{ customer.data.professional.job_title ? `${customer.data.professional.job_title} |` : '' }</small>
                        </div>
                      </th>
                      <td>{customer.data.persona ? customer.data.persona.name : ''}</td>
                      <td>{customer.data.total_orders}</td>
                      <td>{customer.data.lifetime_value}</td>
                      <td className="text-right">
                        <Button onClick={() => { selectCustomer(customer); }} color="primary" size="md" type="button" style={{
                          width: 120
                        }}>
                          View
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>

              <CardFooter className="py-4">
                <nav aria-label="...">
                  <Pagination
                    className="pagination justify-content-end mb-0"
                    listClassName="justify-content-end mb-0"
                  >
                    <PaginationItem className="disabled">
                      <PaginationLink
                        href="#pablo"
                        onClick={(e) => e.preventDefault()}
                        tabIndex="-1"
                      >
                        <i className="fas fa-angle-left" />
                        <span className="sr-only">Previous</span>
                      </PaginationLink>
                    </PaginationItem>
                    <PaginationItem className="active">
                      <PaginationLink
                        href="#pablo"
                        onClick={(e) => e.preventDefault()}
                      >
                        1
                      </PaginationLink>
                    </PaginationItem>
                    <PaginationItem>
                      <PaginationLink
                        href="#pablo"
                        onClick={(e) => e.preventDefault()}
                      >
                        2 <span className="sr-only">(current)</span>
                      </PaginationLink>
                    </PaginationItem>
                    <PaginationItem>
                      <PaginationLink
                        href="#pablo"
                        onClick={(e) => e.preventDefault()}
                      >
                        3
                      </PaginationLink>
                    </PaginationItem>
                    <PaginationItem>
                      <PaginationLink
                        href="#pablo"
                        onClick={(e) => e.preventDefault()}
                      >
                        <i className="fas fa-angle-right" />
                        <span className="sr-only">Next</span>
                      </PaginationLink>
                    </PaginationItem>
                  </Pagination>
                </nav>
              </CardFooter>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

// Connect to store
const ConnectedCustomers = connect(state => ({
  profile: state.profile,
  account: state.account,
  knowledge: state.knowledge,
  insights: state.insights,
  report: state.report
}))(Customers);

export default ConnectedCustomers;
