
const config = {
  "account": {
    "contact": "scott@wickedreports.com",
    "id": "wicked-reports",
    "imageUrl": "https://storage.googleapis.com/avant-garde-cdn/axon/accounts/wicked-reports/assets/img/profile.png"
  },
  "assets": {
    "img": {
      "brand": {
        "backgrounds": {
          "profile": "https://storage.googleapis.com/avant-garde-cdn/axon/accounts/wicked-reports/assets/img/brand/backgrounds/profile.png"
        },
        "logo": "https://storage.googleapis.com/avant-garde-cdn/axon/accounts/wicked-reports/assets/img/brand/logo.png",
        "logo-white": "https://storage.googleapis.com/avant-garde-cdn/axon/accounts/wicked-reports/assets/img/brand/logo-white.png"
      }
    }
  },
  "footer": {
    "company": {
      "link": "https://wickedreports.com",
      "name": "Wicked Reports, LLC"
    }
  },
  "theme": {
    "palette": {
      "color-1": "#E6EAF2",
      "color-2": "#374C5F",
      "color-3": "#E19B35",
      "color-4": "#78C16C",
      "color-5": "#FCFCFC"
    }
  }
}

export default config;
